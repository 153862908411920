 
// time line
$primary-color:#ed1b24; 

.bg-secondry{
	background-color: #f6f6f6; 
}
.vertical-timeline{
	max-width: 90% !important;
 }
 .vertical-timeline-element-content {
	font-size: 16px !important;
	box-shadow: 0 2px 3px #ddd !important;
	p{
	   margin-top: 0 !important;
	   color: #000 !important; 
	}
	.vertical-timeline-element-date {
		opacity: 1 !important; 
		color: #000;
	 }
 }
 .timeline-wrapper{
	 .vertical-timeline-element-icon{
		border-radius: 8px;
		box-shadow: none;
		background-color: $primary-color;

	 }
	 .vertical-timeline::before{
		background-color: $primary-color;
	 }

 }
 .vertical-timeline-element-content .vertical-timeline-element-date,
  .vertical-timeline-element-content p {
    font-size: 15px;  
    
}
 

 @media screen and (min-width: 767px) {
	.vertical-timeline-element-content { 
		p{ 
			font-size: 1.1rem;
		 }
	 }
 }

 @media screen and (min-width: 1170px) {
	 .timeline-wrapper{
		.vertical-timeline--two-columns {
			.vertical-timeline-element-content {
				.vertical-timeline-element-date { 
					top: -46px;
					font-size: 16px;
					font-size: 2.5rem;
					font-weight: 800;
				}
			}
		}
		.vertical-timeline-element-date {
			opacity: 1 !important; 
		 }
	 }
	 
  }