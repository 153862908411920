
@import "./scss/main.scss"; 
/* styles.css or App.css */
@font-face {
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 400; /* Regular */
   src: local('Poppins-Regular'), local('Poppins-Regular'),
        url('./../public/assets/vendors/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
 }
 
 @font-face {
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 700; /* Bold */
   src: local('Poppins-Bold'), local('Poppins-Bold'),
        url('./../public/assets/vendors/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
 }
 
 /* Add more @font-face rules for other weights/styles if needed */
 
 body {
   font-family: 'Poppins', sans-serif;
 }
 
body{
   color: #333;
}

 img{
    max-width: 100%;
    height: auto;
 }
a{
   text-decoration: none !important;
}
 .image-content-section{
   .content{
      h3{ 
         padding: 5px 8px;
         display: inline-block;   
      -webkit-transition: ease-out 0.4s;
      -moz-transition: ease-out 0.4s;
      transition: ease-out 0.4s;
      position: relative; 
      overflow: hidden;
      z-index: 1;
      &::before{
         content: "";
         position: absolute;
         top: 0;
         left: -100%;
         width: 100%;
         height: 100%;
         background-color: #ed1b24;
         transition: left 0.5s ease;
         z-index: -1;
      }
      
      }
     
   }
   &:hover{
      h3{
         color: #fff;
         &::before{
          
            left: 0;
           
         }
      }
   }
 }

 .bg-light-gray{
   background-color: #f4f4f4;
}

.phighlight{
   
      h3,b{ 
         padding: 0px 2px;
         display: inline-flex;
      -webkit-transition: ease-out 0.4s;
      -moz-transition: ease-out 0.4s;
      transition: ease-out 0.4s;
      position: relative; 
      overflow: hidden;
      z-index: 1;
      &::before{
         content: "";
         position: absolute;
         top: 0;
         left: -100%;
         width: 100%;
         height: 100%;
         background-color: #ed1b24;
         transition: left 0.5s ease;
         z-index: -1;
      }
      
      }
     
   
   &:hover{
      h3,b{
         color: #fff;
         &::before{
          
            left: 0;
           
         }
      }
   }
 }

 .bg-light-gray{
   background-color: #f4f4f4;
}


.bg-theme{
   background-color: #ed1b24;
} 

.home-ourproduct-section {
   .card-img-top {
      margin: 0 auto;
      max-width: 200px;
      padding: 20px;
   }
   .product-title{
      margin-bottom: 20px;
      margin-bottom: 15px;
      &:hover{
         a{
            border-bottom: 0;
         }
        
      } 
   }
}

.home-cate-slide{
   .item{
      &:hover{
         transform: translate(0);
      }
   }
}




.slick-prev{
   &.slick-arrow {
     &::before{
      content: url(../public/assets/images/icon/angle-left.svg);
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: -10px;
      left: -10px;
     }
     
      &.slick-disabled{
         opacity: 0.8;
         &::before{
            opacity: 0.8;
           }
      }
   }
}
.slick-next{
   &.slick-arrow {
     &::before{
      content: url(../public/assets/images/icon/angle-right.svg);
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: -10px;
      left: -10px;
     }
      &.slick-disabled{
         opacity: 0.8;
      }
   }
}
 

// wave devider

.wave-devider::before{
   content: '';
   position: absolute;
   left: 0;
   top: -5px;
   height: 110px;
   background-image: url('../public/assets/images/background/wave.svg');
   background-repeat: no-repeat;
   background-size: cover;
   width: 100%;
   background-position: center;
}

// Form info css

.form-control{
   border-color: #878787;
}
.form-group{
   margin-bottom: 20px;
}
 
.header-sticky-smart .sticky-area{
   position: fixed;
}



@media screen and (min-width: 1366px){
   .wave-devider::before{
      height: 170px;
      top: -50px;
   }
}
@media screen and (min-width: 1920px){
   .wave-devider::before{
      height: 230px;
      top: -70px;
   }
}


@media screen and (min-width: 2500px){
   .wave-devider::before{
      display: none;
   }
}
// footer css
 
 
@media screen and (max-width: 992px){
   .wave-devider::before{
      height: 80px;
      top: -20px;
   }
   .coca-section{
      .bg-image{
         background-size: contain;
      }
   }

}
@media screen and (max-width: 768px){
   .navbar-nav{
      background-image: linear-gradient(to right top, #755151, #573d3f, #3b2a2c, #20191a, #000000) !important;
     
      .nav-item{
         .nav-link{
            color: #fff;
         }
      }
   }
   .wave-devider::before{
      height: 60px;
   
   }
   .coca-section{
      p{
         text-align: center;
      }
   }

   // header css
   .navbar-brand{
      width: 100%;
   }

   .navbar-nav {
      background: #fff;
      display: flex;
      height: 100%;
      height: 100vh;
      left: 0;
      overflow: auto;
      position: fixed;
      top: 0;
      -webkit-transform: translate(-100%);
      transform: translate(-100%);
      transition: width .3s;
      -webkit-transition: all .2s ease-in-out;
      width: 320px;
      will-change: transform;
      z-index: 9999;
      max-width: calc(100% - 80px);

      &.show-nav{
         box-shadow: 0 8px 8px 0 rgba(50,50,50,.4);
         -webkit-transform: translate(0);
         transform: translate(0);
         display: flex;
         .nav-item{
            .nav-link{
               font-size: 20px;
            }
            &.py-11{    
                padding-top: 1.5rem !important;
               padding-bottom: 1.5rem !important;}
         }
      }
   }


   // Header css end
   h2, .h2 , h3 {
      font-size: 2rem;
  }
   .image-content-section {
      .content{
         h3{
            padding-left: 0;
         }
      }
   }
}

