.category-list {
	margin: 0 auto;
	display: grid;
	grid-gap: 2rem;
	gap: 2rem;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	font-size: 20px;
	line-height: 26px;
	color: #000;

	.item {
		position: relative;
		box-shadow:(0px 3px 4px rgba(0, 0, 0, .1));
		border-radius: 10px;
		text-align: center;
		border: 1px solid #ddd;
		overflow: hidden;
		-moz-transition: all .2s ease-in-out;
		-o-transition: all .2s ease-in-out;
		-webkit-transition: all .2s ease-in-out;
		transition: all .2s ease-in-out;

		.product-link {
			padding: 20px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;
			background-color: #f4f4f4;
		}

		&:hover {
			border-color: #ed1b24;
			transform: translateY(-15px);

			.category-social-media {
				opacity: 1; 
				transform: translateX(-3px);
			}
		}
	}
}

.product-detail {
	padding: 15px;
	padding-bottom: 20px;
	.qty{
		font-size: 14px;
		margin-bottom: 10px;
		span{
			margin: 0 5px;
		}
	}
}

.product-price {
	font-weight: 600;
	margin-bottom: 10px;
}

.product-name {
	margin-bottom: 10px;
}

.product-image {
	margin-bottom: 0;
	img {
		max-width: 200px;
		max-height: 200px;
	}
}


.category-social-media {
	-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
	position: absolute;
	top:30px;
    right: 0; 
	z-index: 15; 
	transform: translateX(101%);  
	.icon{
		background: $primary-color;
		width: 30px;
		max-width: 30px;
		padding: 5px;
		min-width: 30px;
		min-height: 30px;
		display: flex;
		width: 100%;
		text-align: center;
		margin-bottom: 5px; 
		justify-content: center;
		align-items: center;
		color: #fff;
		border-radius: 3px;
		-moz-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	-webkit-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
		&:hover{
			text-decoration: none;
			transform: translateY(-3px);
		}
	}

}



.breadcrumbs {
	background-color: #ed1b24;
	color: #fff;
	li{ 
		&:not(:last-child){
			&::after{
				content: "/";
				margin-right: 25px;
				font-family: "Font Awesome 6 Brands";
			}
		}
	} 
	a {
		color: #fff;
	}
}



//   All media query start Here
@media screen and (min-width: 991px) {
	.category-list {

		grid-template-columns: repeat(4, 1fr)
	}
}

@media screen and (max-width: 991px) {
	.aboutus-page {
		padding-top: 160px;
	}
	.list-page{
		padding-top: 130px;
	}
	.bg-overlay{
		opacity: 0.25;
	}
}